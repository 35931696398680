import React, {
  useContext,
  useEffect,
  useRef,
  useState
} from 'react'
import { toast } from 'react-hot-toast'
import { useTranslation } from 'react-i18next'
import { AiOutlineMenu, AiOutlineSearch } from 'react-icons/ai'
import { RxCross1 } from 'react-icons/rx'
import { LazyLoadImage } from 'react-lazy-load-image-component'
import { useNavigate } from 'react-router-dom'
import { GlobalContext } from '../../appContext/GlobalContext'
import { TextInput } from '../../common'
import useValidProductURL from '../../hooks/useValidProduct'
import useViewportWidth from '../../hooks/useViewportWidth'
import { ProductBusketContext } from '../../layouts/ProductLayout'
import { formatPrice } from '../../utils/number'
import HamburgerInside from './HamburgerInside'


export const languages = [
  {
    title: 'Romania',
    path: '/assets/images/ro.png',
    value: 'ro',
  },
  {
    title: 'English',
    path: '/assets/images/en.png',
    value: 'en',
  },
  {
    title: 'Hungary',
    path: '/assets/images/hungary.png',
    value: 'hu',
  },
]



const cleanData = (data, selectedLanguage) => {
  //const cleanData = (data) => {1
  const res = data.reduce((result, item) => {
    const getProductName = (item, language) => {
      const key = `Name${language}`
      return item[key] || item.Name
    }

    const productName = getProductName(item, selectedLanguage)

    const getDesc = (product, language) => {
      const key = `Description${language}`;
      return product[key] || product.Description;
    }

    const finalDesc = getDesc(item, selectedLanguage);

    if (item.IsCategory) {
      return [
        ...result,
        { id: item.ID, name: (selectedLanguage && productName) },
        //{ id: item.ID, name: item.Name }2,
        ...cleanData(item.Items, selectedLanguage),
      ]
    } else {
      return [
        ...result,
        {
          id: item.ID,
          name: (selectedLanguage && productName),
          //name: item.Name3,
          price: item.Price,
          product: item,
          description: selectedLanguage && finalDesc,
          //description: item.Description4,
        },
      ]
    }
  }, [])
  return res
}

const Header02 = () => {
  const { t, i18n } = useTranslation()
  const [language, setLanguage] = useState(i18n.language.slice(0, 2))
  const navigate = useNavigate()
  const menuTimeoutRef = useRef(null)
  const searchTimeoutRef = useRef(null)
  const validURL = useValidProductURL()
  const [search, setSearch] = useState('')
  const viewportWidth = useViewportWidth()
  const isLargeScreen = viewportWidth >= 640
  // const [isOpen, setIsOpen] = useState(false)
  const [isMenuOpen, setMenuOpen] = useState(false)
  const [subMenuIndex, setSubMenuIndex] = useState(0)
  const [showSearch, setShowSearch] = useState(false)
  const [showMenu, setShowMenu] = useState(false)
  const [filteredData, setFilteredData] = useState([])
  const { busket, data, setProduct, setShowDialog } =
    useContext(ProductBusketContext)
  // const { location, colorCode } = useContext(GlobalContext)
  const { location, colorCode, uuid, locationName, selectedLanguage, setSelectedLanguage, autoOrder, isOpen, setLocation } = useContext(GlobalContext)

  // const handleMenuStatus = useCallback(() => {
  //   setIsOpen(!isOpen)
  // }, [isOpen, setIsOpen])

  const handleGotoHome = () => {
    if (location?.logo_image && location?.logo_image !== '') {
      window.scrollTo({
        top: 0,
        behavior: 'smooth',
      })
    } else {
      navigate('/')
    }
  }

  const toggleMenu = (subIndex) => {
    if (subMenuIndex !== subIndex) {
      setMenuOpen(true)
      setSubMenuIndex(subIndex)
      setShowSearch(subIndex === 5)
    } else {
      setMenuOpen((prevState) => !prevState)
    }
  }

  const handleGotoCart = (e) => {
    e.stopPropagation()
    if (((validURL || location?.pickup || location?.delivery)) && location?.autoOrders && isOpen && busketValue > 0) {
      navigate(
        '/food-ordering',
        {
          state: {
            location: `${location?.LocationId}`
          }
        }
      )
      // navigate('/food-ordering')
    } else {
      if (!isOpen) {
        toast.error(t('header.failed_order_message'), {
          position: 'bottom-right',
        })
      } else if (busketValue === 0) {
        toast.error(t('header.failed_busket_value_message'), {
          position: 'bottom-center',
        })
      } else {
        toast.error(t('header.functionality_unavailable'), {
          position: 'bottom-right',
        })

      }
    }
  }

  // const busketValue = busket.reduce((acc, item) => acc + (item?.qty || 0), 0)
  const busketValue = busket.reduce((acc, item) => {
    if (item.locationId === location?.LocationId) {
      return acc + (item?.qty || 0);
    }
    return acc;
  }, 0);
  const busketHeaderButton = () => (
    autoOrder && (
      <li
        className={`cursor-pointer relative transition duration-300 ease-in-out hover:bg-gray-100 rounded-[10px] p-[10px] text-[#ffb800] ${isMenuOpen && subMenuIndex === 2 ? 'bg-gray-100' : ''
          }`}
        onClick={() => toggleMenu(2)}
      >
        <img
          alt="Cart"
          src="/assets/images/icons/basket.svg"
          className="w-[25px] h-[25px]"
          style={{ color: colorCode }}
        />
        {busketValue > 0 && (
          <span className="absolute right-[5px] top-[5px] bg-[#FF0000] text-white rounded-full w-[15px] h-[15px] flex justify-center items-center text-[10px]">
            {busketValue}
          </span>
        )}

        {isMenuOpen && subMenuIndex === 2 && (
          <div className="absolute mt-[20px] bg-white rounded-[10px] shadow-md p-[10px] -right-[99px] w-max border-[1px] border-solid border-[#e5e5e5]">
            <ul className="flex flex-col gap-[18px]">
              {busket?.length === 0 ? (
                <li>
                  <p className="text-black text-[12px]">
                    {t('product.empty_cart')}
                    {/* Nu există niciun produs in coș. */}
                  </p>
                </li>
              ) : (
                <>
                  {busket?.map((x) => {
                    const getProductName = (item, language) => {
                      const key = `Name${language}`
                      return item[key] || item.Name
                    }

                    const productName = getProductName(x.item, selectedLanguage)
                    if (x.locationId === location.LocationId) {
                      return (
                        <li key={x.id} className="flex justify-between items-center">
                          {/* <p className="text-black text-[16px] font-medium">{x.item.Name}{x.qty > 1 && `(${x.qty}x)`}</p> */}
                          <p className="text-black text-[16px] max-w-[150px] font-medium">{x.qty > 1 && `${x.qty} x `}{x.item && productName}</p>


                          <p className="text-black text-[14px] font-normal">{(((x.item.discountedProductPrice?x.item.discountedProductPrice:x.item.Price) * x.qty) + (x.modifiers.length ? x.modifiers.reduce((result, modifier) => result + (modifier?.quantity ? (modifier?.Price || 0) * (modifier?.quantity || 0) : (modifier?.Price || 0)), 0) : 0)).toFixed(2)} RON</p>
                        </li>
                      )
                    }
                  })}
                  <li key={null} className="flex justify-center w-100% mb-[10px] ">
                    <button style={{
                      width: "252px",
                      height: "40px",
                      display: "block",
                      margin: "0 auto",
                      textAlign: "center"


                    }} type="button" className="text-base font-medium text-gray-900 focus:outline-none bg-white rounded-full border border-gray-200 hover:bg-gray-100 focus:z-10 focus:ring-4 focus:ring-gray-100 dark:focus:ring-gray-700 dark:bg-gray-800 dark:text-gray-400 dark:border-gray-600 dark:hover:text-white dark:hover:bg-gray-700 text-center"
                      onClick={handleGotoCart}>{t('header.go_to_cart')}</button>
                    {/* <Button 
                  style={{width:"100%",
                  // borderColor:"#CACACA",
                  color:"black",
                  borderColor:"grey",
                  

                }}
                  // colorCode={colorCode}
                    variant="outline"
                    text={
                      // t('header.go_to_cart')
                      t('header.go_tofffff')
                    }
                    onClick={handleGotoCart}
                  /> */}
                  </li>
                </>
              )}
            </ul>
          </div>
        )}
      </li>)
  )

  const handleChangeLanguage = (e, v) => {
    i18n.changeLanguage(v)
    setLanguage(v)
    localStorage.setItem('language', v)
  }

  const currentLanguage = localStorage.getItem('selectedLanguage');

  useEffect(() => {
    // const storedLanguage = localStorage.getItem('language')
    if (selectedLanguage) {
      i18n.changeLanguage(selectedLanguage?.toLowerCase())
    } else if (currentLanguage) {
      i18n.changeLanguage(currentLanguage?.toLowerCase());
      setSelectedLanguage(currentLanguage);
    } else {
      i18n.changeLanguage('ro')
    }
    // if (storedLanguage) {
    //   setLanguage(storedLanguage)
    // }
  }, [])

  const languageHeaderButton = () => (
    <li
      className={`cursor-pointer relative transition duration-300 ease-in-out hover:bg-gray-100 rounded-[10px] p-[10px] text-[#ffb800] ${isMenuOpen && subMenuIndex === 4 ? 'bg-gray-100' : ''
        }`}
      onClick={() => toggleMenu(4)}
    >
      <div
        className="w-[30px] border-[2px] border-solid border-[#ffb800] rounded-full h-[30px]"
        style={{
          backgroundImage: `url(${languages.find((x) => x.value === language).path
            })`,
          backgroundSize: '100% 100%',
        }}
      ></div>

      {isMenuOpen && subMenuIndex === 4 && (
        <div className="absolute mt-[20px] bg-white rounded-[10px] shadow-md p-[10px] right-[0px] w-[200px] border-[1px] border-solid border-[#e5e5e5]">
          <ul className="flex flex-col gap-[10px]">
            {languages.map((x) => (
              <li
                key={x.value}
                className="flex justify-between items-center"
                onClick={(e) => handleChangeLanguage(e, x.value)}
              >
                <p className="text-black text-[18px]">{x.title}</p>
                <img src={x.path} className="w-[30px]" alt="x.value" />
              </li>
            ))}
          </ul>
        </div>
      )}
    </li>
  )

  const handleChangeSearch = (e) => {
    setSearch(e.target.value)
    if(e.target.value.length>2){
      const filteredD = data
        ? cleanData(data, selectedLanguage)
          //? cleanData(data)5
          .filter((item) => {
            const itemName = removeDiacritics(item?.name?.toLowerCase())
            const itemDescription = removeDiacritics(
              item?.description
                ? stripHtmlTags(item.description?.toLowerCase())
                : ''
            )
            const searchLower = removeDiacritics(search?.toLowerCase())
            return (
              itemName?.split(' ')?.some((word) => word?.startsWith(searchLower)) ||
              itemDescription
                .split(' ')
                .some((word) => word?.startsWith(searchLower))
            )
          })
          .sort((a, b) => {
            const itemNameA = removeDiacritics(a?.name?.toLowerCase())
            const itemNameB = removeDiacritics(b?.name?.toLowerCase())
            const searchLower = removeDiacritics(search?.toLowerCase())
    
            if (
              itemNameA?.includes(searchLower) &&
              !itemNameB?.includes(searchLower)
            ) {
              return -1
            } else if (
              !itemNameA?.includes(searchLower) &&
              itemNameB?.includes(searchLower)
            ) {
              return 1
            } else {
              return itemNameA?.localeCompare(itemNameB)
            }
          })
        : []
  
      setFilteredData(filteredD)
      
    }else{
      setFilteredData([])

    }
  }

  const handleClearSearch = () => {
    setSearch('')
  }

  useEffect(() => {
    // Clear any existing timeout when the effect runs
    if (menuTimeoutRef.current !== null) {
      clearTimeout(menuTimeoutRef.current)
    }

    if (isMenuOpen) {
      menuTimeoutRef.current = setTimeout(() => {
        setMenuOpen(false)
      }, 5000)
    }

    // Cleanup
    return () => {
      if (menuTimeoutRef.current !== null) {
        clearTimeout(menuTimeoutRef.current)
      }
    }
  }, [isMenuOpen, subMenuIndex])

  useEffect(() => {
    if (search) {
      document.body.classList.add('no-scroll')
    } else {
      document.body.classList.remove('no-scroll')
    }
    // When the modal is hidden or the component is unmounted, remove the class
    return () => {
      document.body.classList.remove('no-scroll')
    }
  }, [search])

  const removeDiacritics = (str) => {
    return str?.normalize('NFD')?.replace(/[\u0300-\u036f]/g, '')
  }

  function stripHtmlTags(html) {
    return html.replace(/(<([^>]+)>)/gi, '')
  }




  const handleSearchItemClick = (id, product) => {
    if (product) {
      setProduct(product)
      setShowDialog(true)
    } else {
      const element = document.getElementById(id)
      element.scrollIntoView()
    }
    setSearch('')
    setShowSearch(false)
  }

  const handleSearchBoxBlur = () => {
    // Clear any existing timeout when the effect runs
    if (searchTimeoutRef.current !== null) {
      clearTimeout(searchTimeoutRef.current)
    }

    searchTimeoutRef.current = setTimeout(() => {
      setShowSearch(false)
    }, 3000)

    setMenuOpen(false)
  }

  const handleSearchBoxFocus = () => {
    // Clear any existing timeout when the effect runs
    if (searchTimeoutRef.current !== null) {
      clearTimeout(searchTimeoutRef.current)
    }
  }

  useEffect(() => {
    const handleScroll = () => {
      const currentScrollPos = window.scrollY
    }
    window.addEventListener('scroll', handleScroll)
    return () => {
      window.removeEventListener('scroll', handleScroll)
    }
  }, [])

  return (
    <div className="h-[80px] fixed p-[5px] w-full z-[1] app-header">
      <div className="flex justify-between items-center backdrop-blur-md shadow-lg rounded-[10px] bg-[#ffffff5e] pr-[15px] py-[15px]">
        <LazyLoadImage
          alt="adhoc logo"
          className={`cursor-pointer ${location?.logo_image &&
            location?.logo_image !== '' &&
            'h-[45px] w-[95px] object-contain'
            }`}
          src={
            location?.logo_image && location?.logo_image !== ''
              ? location?.logo_image
              : '/assets/images/adhoc_text.png'
          }
          width={95}
          height={45}
          onClick={handleGotoHome}
          effect="opacity"
        />
        {/* {isLargeScreen ? (
              <ul className="flex gap-[20px] items-center justify-center">
                <li
                    className={`cursor-pointer relative transition duration-300 ease-in-out hover:bg-gray-100 rounded-[10px] p-[10px] text-[#ffb800] ${
                        isMenuOpen && subMenuIndex === 1 ? 'bg-gray-100' : ''
                    }`}
                    onClick={() => toggleMenu(1)}
                >
                  <AiOutlineHeart className="w-[30px] h-[30px]" />

                  {isMenuOpen && subMenuIndex === 1 && (
                      <div className="absolute mt-[20px] bg-white rounded-[10px] shadow-md p-[10px] right-[0px] w-[300px] border-[1px] border-solid border-[#e5e5e5]">
                        <ul className="flex flex-col gap-[10px]">
                          <li>
                            <p className="text-black text-[12px]">
                              Add your favourite food, restaurants or another
                            </p>
                          </li>
                        </ul>
                      </div>
                  )}
                </li>
                {busketHeaderButton()}
                <li
                    className={`relative cursor-pointer transition duration-300 ease-in-out hover:bg-gray-100 rounded-[10px] p-[10px] text-[#ffb800] ${
                        isMenuOpen && subMenuIndex === 3 ? 'bg-gray-100' : ''
                    }`}
                    onClick={() => toggleMenu(3)}
                >
                  <AiOutlineUser className="w-[30px] h-[30px]" />

                  {isMenuOpen && subMenuIndex === 3 && (
                      <div className="absolute mt-[20px] bg-white rounded-[10px] shadow-md p-[10px] right-[0px] w-[300px] border-[1px] border-solid border-[#e5e5e5]">
                        <ul className="flex flex-col gap-[10px]">
                          <li>
                            <p className="text-black text-[12px]">
                              Log in to your Adhoc account and have full access to the
                              entire shopping list
                            </p>
                          </li>
                          <li className="flex justify-around">
                            <Button text="Login" variant="outline" />
                            <Button text="New account" variant="outline" />
                          </li>
                        </ul>
                      </div>
                  )}
                </li>
              </ul>
          ) : ( */}


        <ul className="flex gap-[5px] items-center justify-center">
          {location?.autoOrders && busketHeaderButton()}
          <li
            className={`cursor-pointer relative transition duration-300 ease-in-out hover:bg-gray-100 rounded-[10px] text-[#ffb800] p-[10px]`}
            onClick={() => {
              if (!showSearch) {
                handleSearchBoxBlur()
              }
              setShowSearch(!showSearch)
              setSearch('')
              toggleMenu(5)
            }}
          >
            <img
              alt="Search Product"
              src="/assets/images/icons/search.svg"
              className="w-[25px] h-[25px]"
            />
          </li>
          {/* {(location?.clear_table || location?.call_waiter || location?.ask_bill) && (  ***CONDITION FOR SHOWING BURGER MENU*** */}
          <li>
            <button
              className=" p-[10px]"
              onClick={() => setShowMenu(true)}
            >
              <AiOutlineMenu size="1.5em" />
            </button>
          </li>
          {/* )} */}
        </ul>

        {/* )} */}
      </div>


      <HamburgerInside
        locationId={location?.LocationId}
        tableId={uuid}
        setShowMenu={setShowMenu}
        active={showMenu}
        address={location?.address}
        colorCode={colorCode}
      />

      {/* {!isLargeScreen && isOpen && (
        <div className="p-[10px] fixed top-0 w-full left-0">
          <div
            id="mobile_menu"
            className={`bg-[#ffffffe0] backdrop-blur-md rounded-[10px] shadow-md p-[20px] transition-opacity duration-300 ${
              isOpen ? 'opacity-100' : 'opacity-0'
            }`}
          >
            <ul className="flex flex-col gap-[5px] text-center">
              <li className="flex justify-between mb-[20px]">
                <img
                  src="/assets/images/adhoc_text.png"
                  width={100}
                  alt="adhoc logo"
                />
                <button
                  className="shadow transition duration-300 ease-in-out hover:bg-gray-100 hover:shadow-lg rounded-[50%] p-[10px]"
                  onClick={handleMenuStatus}
                >
                  <RxCross2 />
                </button>
              </li>
              <li
                className={`cursor-pointer transition duration-300 ease-in-out hover:bg-gray-100 rounded-[10px] p-[10px] text-[#ffb800] flex items-center justify-start gap-[50px]`}
              >
                <AiOutlineHeart className="w-[30px] h-[30px]" /> View favorites
              </li>
              <li
                className={`relative cursor-pointer transition duration-300 ease-in-out ${
                  busket.length === 0
                    ? 'opacity-50 pointer-events-none'
                    : 'hover:bg-gray-100'
                } rounded-[10px] p-[10px] text-[#ffb800] flex items-center justify-start gap-[50px]`}
                onClick={handleGotoCart}
              >
                <BsCart4 className="w-[30px] h-[30px]" /> Go to cart
                {busket.length !== 0 && (
                  <span className="absolute left-[5px] top-[5px] bg-[#FF0000] text-white rounded-full w-[15px] h-[15px] flex justify-center items-center text-[10px]">
                    {busket.length}
                  </span>
                )}
              </li>
              <li
                className={`cursor-pointer transition duration-300 ease-in-out hover:bg-gray-100 rounded-[10px] p-[10px] text-[#ffb800] flex items-center justify-start gap-[50px]`}
              >
                <AiOutlineUser className="w-[30px] h-[30px]" /> Login
              </li>
              <li
                className={`cursor-pointer transition duration-300 ease-in-out hover:bg-gray-100 rounded-[10px] p-[10px] text-[#ffb800] flex items-center justify-start gap-[50px]`}
              >
                <BsPersonPlus className="w-[30px] h-[30px]" /> New account
              </li>
            </ul>
          </div>
        </div>
      )} */}

      {showSearch && (
        <div className="">
          {/* mt-[5px] mx-auto w-[95%] */}
          <TextInput
            value={search}
            onChange={handleChangeSearch}
            className="flex shadow-lg border "
            icon={<AiOutlineSearch color={colorCode} />}
            // placeholder="Caută produsul după denumire ..."
            // action={<RxCross1 color='black'  />}
            placeholder={t('product.search_placeholder')}
            action={<RxCross1 color='black' />}
            handleAction={handleClearSearch}
            onBlur={handleSearchBoxBlur}
            onFocus={handleSearchBoxFocus}
          />
          {search && search.length > 2 && (
            <div className="w-[80%] shadow-lg mx-auto mt-[3px] bg-white rounded-[10px] max-h-[300px] overflow-y-auto border p-[10px]">
              {filteredData.length > 0 ? (
                filteredData.map((item) => {

                  const getProductName = (item, language) => {
                    const key = `Name${language}`
                    return item[key] || item.Name
                  }

                  // const productName = getProductName(item, selectedLanguage)
                  const productName = item['name']

                  return (
                    < div
                      key={item.id}
                      onClick={() => handleSearchItemClick(item.id, item.product)}
                    >

                      <div className="flex justify-between p-[12px]">
                        <h3>{selectedLanguage && productName}</h3>
                        {/* <div className="flex justify-between p-[10px] text-center content-center ">
                      <h3 style={{color:"#130D00"}} className='font-bold, font-medium , text-base text-left'>{item.name}</h3> */}
                        {item.price ? (
                          // <h3 className="font-normal, text-sm " style={{color:"#3C3A34" }}>
                          //   {formatPrice(item.price)}
                          //   <small>RON</small>
                          // </h3>
                          <h3 className="font-normal text-sm" style={{ color: "#3C3A34" }}>
                            {formatPrice(item.price)}
                            <span style={{ marginLeft: '5px' }}>RON</span>
                          </h3>
                        ) : (
                          <h4 className="font-normal">Category</h4>
                        )}
                      </div>
                      <div
                        className={`mx-auto w-[90%] h-[2px] bg-[${colorCode}]`}
                      ></div>
                    </div>
                  )
                })
              ) : (
                <div className="flex justify-between p-[10px]">
                  <h3>No matched data</h3>
                </div>
              )}
            </div>
          )
          }
        </div>
      )
      }
    </div >
  )
}

export default Header02
